<template>
  <div v-if="formData" class="details--body__edit">
    <v-row v-for="(fieldValue, key) in formData" :key="key">
      <template v-if="fieldIsSelectMultiple(key)">
        <v-col cols="12" class="py-1">
          <strong>{{ $t(`project.${key}`) }}:</strong>
        </v-col>
        <v-col cols="12" class="py-1">
          <v-select
            :value="formData[key]"
            :items="selects[key]"
            item-text="value"
            item-value="key"
            multiple
            chips
            dense
            hide-details
            @input="setFormDataCriterias"
          ></v-select>
        </v-col>
      </template>
      <template v-else-if="key === 'detailsTitle'">
        <div class="ml-3 d-flex align-center">
          <h2 class="details--header-title">{{ $t(`project.${key}`) }}</h2>
          <v-btn
              v-if="$store.getters['project/getCurrentProjectId']"
              :loading="loading"
              text
              icon
              @click="$emit('buttonEvent', false)"
              class="ma-2"
          >
            <IconEdit v-if="!editMode && !loading" />
            <v-icon v-else color="primary">mdi-check-circle-outline</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-else>
        <v-col md="6" class="py-1">
          <strong>{{ $t(`project.${key}`) }}:</strong>
        </v-col>
        <v-col md="6" class="py-1">
          <v-select
            v-model="formData[key]"
            v-if="fieldIsSelect(key)"
            :items="selects[key]"
            item-text="value"
            item-value="key"
            dense
            hide-details
          ></v-select>
          <div v-else class="form-control">
            <v-text-field
              v-if="key !== 'end_date'"
              v-model="formData[key]"
              hide-details
            ></v-text-field>

            <v-menu
              v-else
              :close-on-content-click="true"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formData[key]"
                  outline
                  single-line
                  hide-details
                  v-on="on"
                  autocomplete="off"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="formData[key]"
                color="info"
                :locale="$i18n.locale"
              ></v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SummaryOfEstimateEdit",
  props: {
    model: {
      type: Object,
      required: true,
    },
    loading: Boolean,
    editMode: Boolean,
  },
  inject: ["projectDicts"],
  data() {
    return {
      formData: null,
      selects: Object.assign({}, this.projectDicts()),
    };
  },
  computed: {
    fieldIsSelect() {
      return (key) => {
        return key in this.selects && key !== "criterias";
      };
    },
    fieldIsSelectMultiple() {
      return (key) => {
        return key === "criterias";
      };
    },
  },
  created() {
    this.formData = Object.assign({}, this.model);
    delete this.formData.roomsRenovate;
    Object.keys(this.selects).forEach((field) => {
      this.selects[field] = Object.keys(this.selects[field]).map((keyItem) => {
        return {
          key: keyItem,
          value: this.selects[field][keyItem],
        };
      });
    });
    this.selects.need_an_architect = [...this.selects.simple_choice];
    this.selects.need_an_education = [...this.selects.simple_choice];
    this.selects.need_credit = [...this.selects.simple_choice];
  },
  watch: {
    formData: {
      deep: true,
      handler(newVal) {
        this.$emit("updateModel", newVal);
      },
    },
  },
  methods: {
    setFormDataCriterias(payload) {
      this.$set(this.formData, "criterias", payload);
    },
  },
};
</script>

<style></style>
